import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';
import dayjs from '@/plugins/dayjs';

export const index = async (...args) => {
  const response  = await getPaginated('time-registration', ...args);
  response.data.data = response.data.data.map(data => {
    return {
      ...data,
      week: dayjs(data.from).week(),
    };
  });
  return response;
};

export const timeRegistrationDetails = (id) => get(`time-registration/${id}/component`);

export const approve = (id) => post(`time-registration/${id}/approve`);

export const price = async (id) => {
  const response = await get(`time-registration/${id}/price`);
  response.data.data = {
    totalPurchase: (response.data.data.totalPurchase / 100).toFixed(2),
    totalSales: (response.data.data.totalSales / 100).toFixed(2),
    purchase: response.data.data.purchase.map((purchase) => ({
      ...purchase,
      grossHourlyWage: (purchase.grossHourlyWage / 100).toFixed(2),
      premiumPercentage: (purchase.premiumPercentage / 100).toFixed(2),
      premiumAmount: (purchase.premiumAmount / 100).toFixed(2),
      premiumWage: (purchase.premiumWage / 100).toFixed(2), // premium (ort) (calulation of premiumAmount or premiumPercentage)
      amount: (purchase.amount / 100).toFixed(2), // hourly purchase amount
      total: (purchase.total / 100).toFixed(2), // amount*hours

    })),
    sale: response.data.data.sale.map((sale) => ({
      ...sale,
      salesAmount: sale.salesAmount ? (sale.salesAmount / 100).toFixed(2) : 0, // total sales amount
      premiumAmount: sale.premiumAmount ? (sale.premiumAmount / 100).toFixed(2) : 0, //amount*hours
      salesIncrement: sale.salesIncrement ? (sale.salesIncrement / 100).toFixed(2) : 0, // self employed increment (opslag)
    })),
  };

  return response;
};

export const locationDepartmentAutocomplete = (placementId, ...args) => getPaginated(`placement/${placementId}/department/autocomplete`, ...args);

export const indexConceptTimeRegistrations = ({ candidateId, placementId, locationId, departmentId }) => get(`candidate/${candidateId}/time-registration/concept`, {
  params: {
    placementId,
    locationId,
    departmentId,
  },
});

export const saveTimeRegistrations = ({ candidateId, placementId, locationId, departmentId, timeRegistrations }) => post(`candidate/${candidateId}/time-registration`, {
  placementId,
  locationId,
  departmentId,
  timeRegistrations,
});

export const deleteTimeRegistration = (id, data) => post(`time-registration/${id}/delete`, data);

export const show = (timeRegistrationId) => get(`time-registration/${timeRegistrationId}`);

export const update = (timeRegistration) => put(`time-registration/${timeRegistration.id}`, timeRegistration);

export const getTravelCosts = (params) => get('time-registration/travel-cost', { params });

export const calculateBusinessTotal = (placementId, meters) => get('time-registration/calculate-business-allowance', {
  params: {
    placementId,
    meters,
  },
});

export const checkOverlappingTimeRegistrations = async ({ candidateId, timeRegistrations }) =>  post('actionable/candidate-has-overlapping-time-registrations', {
  candidateId,
  timeRegistrations: timeRegistrations.map(timeRegistration => {
    const timeRegistrationFrom = dayjs(`${timeRegistration.date} ${timeRegistration.from}`);
    let timeRegistrationTo = dayjs(`${timeRegistration.date} ${timeRegistration.to}`);

    if (timeRegistrationTo.isBefore(timeRegistrationFrom)) {
      timeRegistrationTo = timeRegistrationTo.add(1, 'day');
    }

    return {
      from: timeRegistrationFrom.format('YYYY-MM-DD HH:mm:ss'),
      to: timeRegistrationTo.format('YYYY-MM-DD HH:mm:ss'),
    };
  }),
});
