<template>
  <v-row>
    <v-col cols="6">
      <KTimeField
        ref="from"
        v-model="timeRegistrationBreak.from"
        field="breakFrom"
        :required="!!(timeRegistrationBreak? timeRegistrationBreak.to:false)"
        :disabled="disabled"
        :rules="[validateFrom]"
        @keypress.native.enter="addTimeRegistration"
      />
    </v-col>
    <v-col cols="6">
      <div
        class="d-flex flex-nowrap"
      >
        <KTimeField
          ref="to"
          v-model="timeRegistrationBreak.to"
          class="flex-grow-1"
          field="breakTo"
          :required="!!(timeRegistrationBreak ? timeRegistrationBreak.from:false)"
          :disabled="disabled"
          :rules="[validateTo]"
          @keypress.native.enter="addTimeRegistration"
        />
        <v-btn
          v-if="showDeleteBreakButton"
          icon
          class="align-self-center ml-2"
          :disabled="disabled"
          tabindex="-1"
          @click="$emit('remove-break')"
        >
          <v-icon color="red">
            $delete
          </v-icon>
        </v-btn>
      </div>
      <div
        v-if="showAddBreakButton"
        class="d-flex text-right"
      >
        <v-spacer />
        <k-btn
          text
          :disabled="disabled"
          @keypress.enter="addBreak"
          @click="addBreak"
        >
          {{ $t('timeRegistration.actions.addBreak') }}
        </k-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import KTimeField from '@/components/crud/fields/KTimeField.vue';
import KBtn from '@/components/KButton.vue';
import dayjs from '@/plugins/dayjs.ts';

export default {
  name: 'TimeRegistrationBreak',
  components: {
    KTimeField,
    KBtn,
  },
  props: {
    newTimeRegistration: {
      type: Object,
    },
    timeRegistrationBreak: {
      type: Object,
    },
    showAddBreakButton: {
      type: Boolean,
      default: false,
    },
    showDeleteBreakButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add-break', 'remove-break', 'add-time-registration'],
  computed: {
    timeRegistrationFrom() {
      return this.convertStringToDayjs(this.newTimeRegistration.from);
    },
    timeRegistrationTo() {
      const timeRegistrationTo = this.convertStringToDayjs(this.newTimeRegistration.to);

      if (timeRegistrationTo.isBefore(this.timeRegistrationFrom)) {
        return timeRegistrationTo.add(1, 'day');
      }

      return timeRegistrationTo;
    },
    breakFrom() {

      const breakFrom = this.convertStringToDayjs(this.timeRegistrationBreak.from);

      if (this.shiftPassesMidnight && breakFrom.isBefore(this.timeRegistrationFrom)) {
        return breakFrom.add(1, 'day');
      }

      return breakFrom;

    },
    breakTo() {
      const breakTo = this.convertStringToDayjs(this.timeRegistrationBreak.to);

      if (this.shiftPassesMidnight && breakTo.isBefore(this.timeRegistrationTo) && breakTo.isBefore(this.breakFrom)) {
        return breakTo.add(1, 'day');
      }

      return breakTo;
    },
    shiftPassesMidnight() {
      return this.timeRegistrationTo.isAfter(this.timeRegistrationFrom, 'date');
    },
    allTimeFieldsFilled() {
      return this.newTimeRegistration?.from && this.timeRegistrationBreak?.from && this.newTimeRegistration?.to && this.timeRegistrationBreak?.to;
    },
  },
  methods: {
    addTimeRegistration() {
      this.$emit('add-time-registration');
    },
    addBreak() {
      this.$emit('add-break');
    },
    convertStringToDayjs(timeString) {
      if (!timeString) return;

      const [hour, minutes] = timeString.split(':');
      return dayjs().hour(hour).minute(minutes);
    },
    validateFrom() {
      if (!this.allTimeFieldsFilled) return true;
      return this.breakFrom.isBetween(this.timeRegistrationFrom, this.timeRegistrationTo) && this.breakFrom.isBefore(this.breakTo) || this.$t('timeRegistration.errors.invalidBreaktime');
    },
    validateTo() {
      if (!this.allTimeFieldsFilled) return true;
      this.$refs.from.$refs.field.validate();
      return this.breakTo.isBetween(this.timeRegistrationFrom, this.timeRegistrationTo) && this.breakFrom.isBefore(this.breakTo) || this.$t('timeRegistration.errors.invalidBreaktime');
    },
  },
};
</script>
