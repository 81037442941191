
import Vue from 'vue';

export default Vue.extend({
  name: 'GeneralInformation',
  props: {
    values: {
      type: Object,
      required: true,
    },
    notApplicable: {
      type: Array,
      default: () => [],
    },
    unfinishedPeriod: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      generalInfoColumns: [
        ['invoiceNumberFormat', 'invoiceDate', 'expirationDate', 'relationNumberCandidate', 'relationNumberEmployer'],
        ['period', 'candidate', 'candidateFunction', 'bigNumber', 'referenceForEmployer', 'position'],
        ['invoiceTypeTitle', 'employer', 'location', 'department', 'contact'],
      ],
    };
  },
  computed: {
    generalInformation(): Record<string, unknown> {
      const values = {
        ...this.values,
        invoiceDate: this.$t('invoice.preview.info.dateFormat'),
        expirationDate: `${this.$t('invoice.preview.info.dateFormat')} (${this.$t('invoice.preview.info.expirySuffix', { termOfPayment: this.values.termOfPayment })})`,
      };

      if (this.values.employer) {
        values.employer = `${this.values.employer} ${this.values.costCenterEmployer ? ` (${this.values.costCenterEmployer})` : ''}`;
      }
      
      if (this.values.location) {
        values.location = `${this.values.location} ${this.values.costCenterLocation ? ` (${this.values.costCenterLocation})` : ''}`;
      }
      
      if (this.values.department) {
        values.department = `${this.values.department} ${this.values.costCenterDepartment ? ` (${this.values.costCenterDepartment})` : ''}`;
      }
      
      return values;
    },
  },
});
