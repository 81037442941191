
import Vue, { PropType } from 'vue';
import AccountingFormat from '@/modules/invoice/views/AccountingFormat.vue';

interface KilometerAllowance {
  meters: number,
  totalAmount: number
}

export default Vue.extend({
  name: 'InvoiceKilometerAllowances',
  components: {
    AccountingFormat,
  },
  props: {
    businessKilometerAllowance: {
      type: Object as PropType<KilometerAllowance>,
    },
    commutingKilometerAllowance: {
      type: Object as PropType<KilometerAllowance>,
    },
    titleColSpan: {
      type: Number,
    },
  },
});
