import Model from '@/application/models/model.js';
import dayjs from '@/plugins/dayjs.ts';

export default class Break extends Model {
  id = null;
  from = null;
  to = null;
  date = null; //only needed for defining when the break occurs, no need to send to server

  mapResponse(data) {
    const date = dayjs(data.from).format('YYYY-MM-DD');
    const from = data.from ? dayjs(data.from).format('HH:mm') : null;
    const to = data.to ? dayjs(data.to).format('HH:mm') : null;
    return super.mapResponse({
      ...data,
      from,
      to,
      date,
    });
  }

  mapForRequest(fromTime, toTime) {

    let breakFromTime = dayjs(`${this.date} ${this.from}`);
    let breakToTime = dayjs(`${this.date} ${this.to}`);

    if (fromTime && toTime && !breakFromTime.isBetween(fromTime, toTime)) {
      breakFromTime = breakFromTime.add(1, 'day');
    }

    if (fromTime && toTime && !breakToTime.isBetween(fromTime, toTime)) {
      breakToTime = breakToTime.add(1, 'day');
    }

    return {
      id: this.id || null,
      from: this.from ? breakFromTime.format('YYYY-MM-DD HH:mm:ss') : null,
      to: this.to ? breakToTime.format('YYYY-MM-DD HH:mm:ss') : null,
    };
  }
}
