<template>
  <div>
    <v-data-table
      :headers="headersToShow"
      :items="items"
      disable-pagination
      disable-sort
      hide-default-footer
      group-by="groupHeader"
      class="invoice-preview-table"
      :class="{ 'hide-header': hideHeader }"
      :dense="originalInvoiceNumber !== null"
    >
      <template
        v-if="showCreditInvoiceHeader"
        #header
      >
        <thead>
          <tr>
            <th :colspan="headersToShow.length">
              <span class="red--text">{{
                $t('invoice.preview.creditHeader', { invoiceNumber: originalInvoiceNumber })
              }}</span>
            </th>
          </tr>
        </thead>
      </template>

      <template #group.header="{ group }">
        <th :colspan="headersToShow.length">
          <div class="d-flex justify-space-between align-center">
            <span>{{ group }}</span>
            <v-btn
              v-if="!isCreditInvoice"
              small
              icon
              rounded
              @click="handleShowTimeRegistrationsDialog(group)"
            >
              <v-icon small>
                $visible
              </v-icon>
            </v-btn>
          </div>
        </th>
      </template>
      <!-- todo: should be fixed at backend -->
      <template #item.totalHours="{ item: { totalHours } }">
        {{ totalHours.replace('.', ',') }}
      </template>
      <template #item.percentage="{ item: { premium } }">
        {{ premium / 100 }}%
      </template>

      <template #item.rate="{ item: { rate } }">
        <AccountingFormat :value="rate / 100" />
      </template>

      <!-- todo: fill in premium rate if available -->
      <template #item.premium="{ }">
        <span>{{ }}</span>
      </template>

      <template #item.description="{ item: { premiumDescription } }">
        {{ $t(`invoice.preview.invoiceRows.${premiumDescription}`) }}
      </template>

      <template #item.date="{ item: { columns } }">
        {{ dayjs(columns.date, 'YYYY-MM-DD').format('DD MMM YYYY') }}
      </template>

      <template #item.from="{ item: { columns } }">
        {{ dayjs(columns.from).format('HH:mm') }}
      </template>

      <template #item.to="{ item: { columns } }">
        {{ dayjs(columns.to).format('HH:mm') }}
      </template>

      <template #item.subTotal="{ item: { subTotal } }">
        <AccountingFormat :value="subTotal / 100" />
      </template>

      <template #foot>
        <tfoot
          v-if="totals"
          class="total-rows"
        >
          <tr>
            <td
              :colspan="headersToShow.length - 1"
              class="text-right font-weight-bold subtotal"
            >
              {{ $t('invoice.preview.totals.subTotal') }}
            </td>
            <td class="subtotal">
              <AccountingFormat :value="totals.subTotal / 100" />
            </td>
          </tr>
          <tr
            v-for="(vatLine, index) in nonZeroVatLines"
            :key="index"
          >
            <td
              :colspan="headersToShow.length - 1"
              class="text-right font-weight-bold vat"
            >
              {{ $t('invoice.preview.totals.vat', { detail: vatDetailText(vatLine) }) }}
            </td>
            <td class="vat">
              <AccountingFormat :value="vatLine.amount / 100" />
            </td>
          </tr>
          <tr>
            <td
              :colspan="headersToShow.length - 1"
              class="text-right font-weight-bold text-uppercase total"
            >
              {{ $t('invoice.preview.totals.total') }}
            </td>
            <td class="font-weight-bold total">
              <AccountingFormat :value="totals.total / 100" />
            </td>
          </tr>
        </tfoot>
        <template v-if="showKilometerAllowances">
          <InvoiceKilometerAllowances
            :title-col-span="headersToShow.length-2"
            :business-kilometer-allowance="kilometerAllowance.businessKilometerAllowance"
            :commuting-kilometer-allowance="kilometerAllowance.commutingKilometerAllowance"
          />
        </template>
      </template>
    </v-data-table>
    <InvoiceTimeRegistrationDialog
      :values="groupData"
      :show-dialog="showTimeRegistrationsDialog"
      @change="$emit('change')"
      @cancel="handleCancelTimeRegistrationsDialog"
    />
  </div>
</template>

<script>
import { invoiceType } from '@/application/enums/invoiceType';
import AccountingFormat from '@/modules/invoice/views/AccountingFormat.vue';
import InvoiceKilometerAllowances from '@/modules/invoice/components/InvoiceKilometerAllowances.vue';
import InvoiceTimeRegistrationDialog from '@/modules/invoice/components/InvoiceTimeRegistrationDialog';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'InvoiceTable',
  components: {
    InvoiceKilometerAllowances,
    AccountingFormat,
    InvoiceTimeRegistrationDialog,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    invoiceTypeId: {
      type: Number,
    },
    totals: {
      type: Object,
    },
    kilometerAllowance: {
      type: [Object, Array],
      default: null,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    showCreditInvoiceHeader: {
      type: Boolean,
      default: false,
    },
    originalInvoiceNumber: {
      type: [String, Number],
      default: null,
    },
    isCreditInvoice: {
      type: Boolean,
      default: false,
    },
    invoiceGroupData: {
      type: [Array, Object],
      required: true,
    },
  },
  data: () => ({
    groupData: null,
    showTimeRegistrationsDialog: false,
    selectedGroupHeader: null,
  }),
  computed: {
    itemsHaveColumns() {
      if (this.items.length === 0) {
        return false;
      }

      if (this.items[0].columns !== null) {
        return true;
      }

      return false;
    },
    headersToShow() {
      return this.headers.filter(header => header.show);
    },
    headers() {
      return [
        {
          text: this.$t('invoice.preview.invoiceRows.percentage'),
          value: 'percentage',
          width: '40',
          align: 'center',
          show: this.invoiceTypeId === invoiceType.INTERMEDIARY_TO_SELF_EMPLOYED_MEDIATION,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.date'),
          value: 'date',
          width: '120',
          align: 'start',
          show: this.itemsHaveColumns,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.description'),
          value: 'description',
          align: 'start',
          show: this.invoiceTypeId === invoiceType.INTERMEDIARY_TO_SELF_EMPLOYED_MEDIATION,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.premiumDescription'),
          value: 'premiumDescription',
          align: 'start',
          show: this.invoiceTypeId !== invoiceType.INTERMEDIARY_TO_SELF_EMPLOYED_MEDIATION,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.from'),
          value: 'from',
          width: '80',
          align: 'start',
          show: this.itemsHaveColumns,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.to'),
          value: 'to',
          width: '80',
          align: 'start',
          show: this.itemsHaveColumns,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.totalHours'),
          value: 'totalHours',
          width: '200',
          align: 'end',
          show: this.invoiceTypeId !== invoiceType.INTERMEDIARY_TO_SELF_EMPLOYED_MEDIATION,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.rate'),
          value: 'rate',
          width: '200',
          align: 'end',
          show: this.invoiceTypeId !== invoiceType.INTERMEDIARY_TO_SELF_EMPLOYED_MEDIATION,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.premium', { unit: '%' }),
          value: 'premium',
          width: '200',
          align: 'end',
          // todo: only show if premium percentage is available
          show: this.invoiceTypeId !== invoiceType.INTERMEDIARY_TO_SELF_EMPLOYED_MEDIATION,
        },
        {
          text: this.$t('invoice.preview.invoiceRows.subTotal'),
          value: 'subTotal',
          width: '200',
          align: 'end',
          show: true,
        },
      ];
    },
    nonZeroVatLines() {
      return this.totals.vatTotals.filter(total => total.percentage !== 0);
    },
    showKilometerAllowances() {
      if (!this.kilometerAllowance || this.kilometerAllowance.length === 0) {
        return false;
      }
      return this.kilometerAllowance.businessKilometerAllowance?.totalAmount !== 0 ||
        this.kilometerAllowance.commutingKilometerAllowance?.totalAmount !== 0;
    },
  },
  watch: {
    async invoiceGroupData() {
      this.handleShowTimeRegistrationsDialog(this.selectedGroupHeader);
    },
  },
  methods: {
    dayjs,
    handleShowTimeRegistrationsDialog(groupHeader) {
      this.selectedGroupHeader = groupHeader;
      if (this.invoiceTypeId === invoiceType.INTERMEDIARY_TO_SELF_EMPLOYED_MEDIATION){
        this.groupData = {
          groupHeader: this.invoiceGroupData[0].groupHeader,
          employer: this.invoiceGroupData[0].employer,
          candidate: this.invoiceGroupData[0].candidate,
          timeRegistrations: this.invoiceGroupData.flatMap(group => group.timeRegistrations),
        };
        this.showTimeRegistrationsDialog = true;
        return;
      }
      this.groupData = this.invoiceGroupData.find(group => group.groupHeader === groupHeader);
      this.showTimeRegistrationsDialog = true;
    },
    handleCancelTimeRegistrationsDialog() {
      this.groupData = null;
      this.showTimeRegistrationsDialog = false;
      this.selectedGroupHeader = false;
    },
    vatDetailText(vatLine) {
      return vatLine.percentage ? this.$n(vatLine.percentage / 10000, 'percent') : this.$t('invoice.preview.totals.exempted');
    },
  },
};
</script>

<style lang="scss" scoped>

:deep(.v-data-table) {
  border-radius: 0;

  &.hide-header {
    .v-data-table-header {
      visibility: hidden;

      th {
        height: 0;

        span {
          display: none;
        }
      }
    }
  }
}

:deep(.theme--light.v-data-table.invoice-preview-table) > .v-data-table__wrapper > table > tfoot {
  background: #e3e4e0;

  tr {
    background-color: rgba(255, 255, 255, 0.25);

    td:not(.v-data-table__mobile-row) {
      height: 32px;
      border: none;

      &.total {
        height:    3em;
        font-size: 1em;
      }
    }
  }

  &.kilometer-allowances {
    tr:last-of-type > td:not(.v-data-table__mobile-row) {
      border-bottom: thin solid rgba(0, 0, 0, 0.2)
    }
  }
}
</style>
