import Model from '@/application/models/model.js';
import dayjs from '@/plugins/dayjs';
import Break from '@/application/models/Break.js';

class TimeRegistrationUpdate extends Model {
  id = 0;
  date = '';
  from = '';
  to = '';
  breaks = [];
  reason = '';
  includeCommutingKilometers = false;
  includeBusinessKilometers = false;
  commutingKilometerAllowance = {
    id: 0,
    kilometers: 0,
    total: 0,
  };
  businessKilometerAllowance = {
    id: 0,
    kilometers: 0,
    total: 0,
  };
  isBilled = false;
  locationId = 0;
  placementId = 0;
  candidateId = 0;
  departmentId = 0;

  mapForRequest() {
    /**
     * The date field is front-end only the api has 2 datetime stamps.
     */
    const fromTime = dayjs(`${this.date} ${this.from}`);
    let toTime = dayjs(`${this.date} ${this.to}`);
    if (fromTime.isAfter(toTime)) {
      toTime = toTime.add(1, 'day');
    }

    return {
      from: fromTime.format('YYYY-MM-DD HH:mm:ss'),
      to: toTime.format('YYYY-MM-DD HH:mm:ss'),
      reason: this.reason,
      breaks: this.breaks.length > 0 ? this.breaks.map(singleBreak => {
        singleBreak.date = this.date;
        return singleBreak.mapForRequest(fromTime, toTime);
      }) : null,
      commutingKilometerAllowance: this.commutingKilometerAllowance.kilometers == 0 || !this.includeCommutingKilometers ? undefined : this.mapKilometersAllowanceRequest(this.commutingKilometerAllowance),
      businessKilometerAllowance: this.businessKilometerAllowance.kilometers == 0 || !this.includeBusinessKilometers ? undefined : this.mapKilometersAllowanceRequest(this.businessKilometerAllowance),
    };
  }

  mapResponse(data) {
    const date = dayjs(data.from).format('YYYY-MM-DD');
    const from = dayjs(data.from).format('HH:mm');
    const to = dayjs(data.to).format('HH:mm');
    const breaks = data.breaks.map(singleBreak => new Break().mapResponse(singleBreak));
    const commutingKilometerAllowance = data.commutingKilometerAllowance != null ? this.mapKilometersAllowanceResponse(data.commutingKilometerAllowance) : this.commutingKilometerAllowance;
    const businessKilometerAllowance = data.businessKilometerAllowance != null ? this.mapKilometersAllowanceResponse(data.businessKilometerAllowance) : this.businessKilometerAllowance;
    const includeCommutingKilometers = data.commutingKilometerAllowance != null;
    const includeBusinessKilometers = data.businessKilometerAllowance != null;
    return super.mapResponse({
      ...data,
      from,
      to,
      date,
      breaks,
      commutingKilometerAllowance,
      businessKilometerAllowance,
      includeCommutingKilometers,
      includeBusinessKilometers,
    });
  }

  mapKilometersAllowanceRequest(input) {
    return {
      id: input.id != 0 ? input.id : undefined,
      meters: Math.round(input.kilometers * 1000),
      total: Math.round(input.total * 100),
    };
  }

  mapKilometersAllowanceResponse(data) {
    data.kilometers = data.meters / 1000;
    data.total = data.total / 100;
    return data;
  }
}

export default TimeRegistrationUpdate;
