/* eslint-disable */
<template>
  <k-field-group language-prefix="timeRegistration.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="[{ name: $t('global.defaultTabName') }]"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row v-if="values.isBilled && canEdit">
          <v-col>
            <v-alert
              outlined
              type="warning"
            >
              {{ $t('timeRegistration.messages.isBilled') }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="hasTimeRegistrationError">
          <v-col>
            <v-alert
              outlined
              type="error"
            >
              {{ $t('timeRegistration.errors.timeRegistration') }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <KDateField
            v-model="values.date"
            required
            :min-date="values.placementStartDate"
            :max-date="dayjs(values.placementEndDate).isBefore(dayjs(), 'day') ? values.placementEndDate : dayjs()"
            field="date"
            :disabled="!canEdit"
          />
          <KTimeField
            v-model="values.from"
            required
            field="from"
            :disabled="!canEdit"
          />
          <KTimeField
            v-model="values.to"
            required
            field="to"
            :disabled="!canEdit"
          />
          <v-row>
            <v-col>
              <TimeRegistrationBreak
                v-for="(newBreak, idx) in values.breaks"
                :key="`break_${idx}`"
                :time-registration-break="newBreak"
                :show-add-break-button="idx === values.breaks.length - 1"
                :show-delete-break-button="true"
                :disabled="!canEdit"
                @add-break="addBreak(values.breaks)"
                @remove-break="removeBreak( values.breaks, idx)"
              />
              <div
                v-if="values.breaks.length === 0"
                class="d-flex text-right align-center"
              >
                <span
                  v-if="shiftDuration(values) >= 5.5"
                  class="red--text"
                >{{ $t('timeRegistration.messages.noBreak') }}</span>
                <v-spacer />
                <k-btn
                  text
                  :disabled="!canEdit"
                  @click="addBreak(values.breaks)"
                >
                  {{ $t('timeRegistration.actions.addBreak') }}
                </k-btn>
              </div>
            </v-col>
          </v-row>
          <KTextarea
            v-model="values.reason"
            required
            field="reason"
            :disabled="!canEdit"
          />
          <v-row>
            <v-col class="col-12 col-sm-3 pt-4">
              <strong :class="{ 'transparent-text': values.isBilled }">{{ $t('timeRegistration.fields.travelCosts') }} </strong>
            </v-col>
            <v-col class="col-12 col-sm-9">
              <v-row
                no-gutters
                align="center"
              >
                <v-col>
                  <KCheckbox
                    v-model="values.includeCommutingKilometers"
                    dense
                    field="commutingTraffic"
                    :disabled="values.isBilled || !commutingTotal"
                  />
                </v-col>
                <v-col class="col-md-4 text-right">
                  <span :class="values.includeCommutingKilometers && !values.isBilled ? '' : 'transparent-text'">{{ $n((commutingKilometers), 'twoDecimals') }} km</span>
                </v-col>
                <v-col class="col-sm-2 text-right">
                  <span :class="values.includeCommutingKilometers && !values.isBilled ? '' : 'transparent-text'">{{ $n(commutingTotal, 'currency') }}</span>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align="center"
              >
                <v-col>
                  <KCheckbox
                    v-model="values.includeBusinessKilometers"
                    dense
                    field="businessTraffic"
                    :disabled="values.isBilled"
                  />
                </v-col>
                <v-col class="col-md-4">
                  <KFigureField
                    v-model="values.businessKilometerAllowance.kilometers"
                    class="text-align-right"
                    label=""
                    autocomplete="off"
                    :disabled="!values.includeBusinessKilometers || values.isBilled"
                    min="0"
                    step="0.01"
                    suffix="km"
                  />
                </v-col>
                <v-col class="col-sm-2 text-right">
                  <span :class="values.includeBusinessKilometers && !values.isBilled ? '' : 'transparent-text'">{{ $n(values.businessKilometerAllowance.total, 'currency') }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KTimeField from '@/components/crud/fields/KTimeField.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';
import { getTravelCosts, calculateBusinessTotal } from '@/modules/timeRegistration/api';
import TimeRegistrationBreak from '@/modules/timeRegistration/components/TimeRegistrationBreak.vue';
import KBtn from '@/components/KButton.vue';
import Break from '@/application/models/Break.js';
import { mapGetters, mapState } from 'vuex';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'TimeRegistrationUpdateForm',
  components: {
    KBtn,
    TimeRegistrationBreak,
    KDateField,
    KTimeField,
    KTextarea,
    KCheckbox,
    KFieldGroup,
    KFormDialog,
    KFigureField,
  },
  props: {
    values: {
      type: Object,
    },
  },
  data() {
    return {
      commutingKilometers: 0,
      commutingTotal: 0,
      businessKilometerAllowance: 0,
      businessTotalTimeout: 0,
    };
  },
  computed: {
    ...mapGetters({ can: 'authorisation/can' }),
    ...mapState('error', ['errors']),
    hasTimeRegistrationError() {
      if (this.errors.length > 0) {
        return this.errors[0].key === 'timeRegistrations';
      }
      return false;
    },
    canEdit() {
      return !this.values.isBilled || (this.values.isBilled && this.canEditBilled);
    },
    canEditBilled() {
      return this.can('timeRegistration.updateAfterBilled');
    },
  },
  watch: {
    'values.candidateId': {
      async handler(newVal) {
        if (newVal !== 0) {
          await this.getTravelCosts();
        }
      },
    },
    'values.businessKilometerAllowance.kilometers': {
      async handler(newVal) {
        clearTimeout(this.businessTotalTimeout);
        this.businessTotalTimeout = setTimeout(async () => {
          await this.calculateBusinessTotal(newVal);
        }, 300);
      },
    },
    'values.includeCommutingKilometers': {
      async handler(newVal) {
        this.values.commutingKilometerAllowance.kilometers = newVal ? this.commutingKilometers : 0;
        this.values.commutingKilometerAllowance.total = newVal ? this.commutingTotal : 0;
      },
    },
  },
  methods: {
    dayjs,
    addBreak(breaks) {
      breaks.push(new Break());
    },
    removeBreak(breaks, idx) {
      breaks.splice(idx, 1);
    },
    shiftDuration({ date, from, to }) {
      const shiftFrom = dayjs(`${date} ${from}`);
      let shiftTo = dayjs(`${date} ${to}`);

      if (shiftTo.isBefore(shiftFrom)) {
        shiftTo = shiftTo.add(1, 'day');
      }
      return dayjs.duration(shiftTo.diff(shiftFrom)).asHours();
    },
    async calculateBusinessTotal(kilometers) {
      if (!this.values.placementId) {
        return;
      }
      const meters = Math.round((kilometers || 0) * 1000);
      const response = await calculateBusinessTotal(this.values.placementId, meters);
      this.values.businessKilometerAllowance.total = response.data.data.total / 100;
    },
    async getTravelCosts() {
      const candidateId = this.values.candidateId;
      const placementId = this.values.placementId;
      const locationId = this.values.locationId;
      const departmentId = this.values.departmentId ? this.values.departmentId : undefined ;

      if (!candidateId || !placementId || !locationId ) return;

      const response = await getTravelCosts({ candidateId, placementId, locationId, departmentId });
      this.commutingKilometers = response.data.data.commutingKilometerAllowance.meters / 1000;
      this.commutingTotal = response.data.data.commutingKilometerAllowance.total / 100;
      this.businessKilometerAllowance = response.data.data.businessKilometerAllowance.allowance / 100;
    },
  },
};
</script>

<style scoped>
.transparent-text {
  opacity: 0.50;
}

.text-align-right:deep(input) {
  text-align: end
}
</style>
