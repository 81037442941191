<template>
  <div>
    <v-dialog
      v-if="values"
      v-model="showDialog"
      max-width="1100"
      @click:outside="closeDialog"
    >
      <v-card tile>
        <v-card-title>
          <h3 class="text-h3 primary--text text-darken-3">
            {{ $t('invoice.preview.timeRegistrationDialog.header') }}
          </h3>
          <VSpacer />
          <v-btn
            icon
            rounded
            @click="closeDialog"
          >
            <v-icon>$clear</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          class="d-flex flex-column"
          :class="{ 'pb-1': !showFooter }"
        >
          <v-sheet
            class="k-transparent pa-4 d-flex flex-column"
            color="rgba(0, 0, 0, 0.03)"
          >
            <table class="general-info">
              <tr
                v-for="detail in generalInfo"
                :key="detail"
              >
                <td class="grey--text text--darken-2 font-weight-bold">
                  {{ $t(`invoice.preview.timeRegistrationDialog.info.${detail}`) }}:
                </td>
                <td>{{ values[detail] }}</td>
              </tr>
            </table>
          </v-sheet>
          <v-data-table
            :headers="headers"
            :items="values.timeRegistrations"
            :hide-default-footer="showFooter"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: '$doubleLeft',
              lastIcon: '$doubleRight',
            }"
          >
            <template #item.date="{ item: { from } }">
              {{ dayjs(from).format('LL') }}
            </template>
          
            <template #item.from="{ item: { from } }">
              {{ dayjs(from).format('HH:mm') }}
            </template>
          
            <template #item.to="{ item: { to } }">
              {{ dayjs(to).format('HH:mm') }}
            </template>
          
            <template #item.hoursTotalTime="{ item: { hoursTotalTime } }">
              {{ $n(hoursTotalTime, 'twoDecimals') }}
            </template>
          
            <template #item.hoursTotalBreak="{ item: { hoursTotalBreak } }">
              {{ $n(hoursTotalBreak, 'twoDecimals') }}
            </template>
          
            <template #item.hoursTotalWorked="{ item: { hoursTotalWorked } }">
              {{ $n(hoursTotalWorked, 'twoDecimals') }}
            </template>

            <template #item.actions="{ item }">
              <v-tooltip
                top
                :disabled="item.isEditable"
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      icon
                      :disabled="!item.isEditable"
                      @click="openUpdate(item)"
                    >
                      <v-icon>$edit</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>{{
                  item.isBilled ? $t('timeRegistration.messages.editDisabledBilled') : $t('timeRegistration.messages.editDisabledApproved')
                }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <TimeRegistrationUpdateForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('timeRegistration.title', 1)"
      :values="updateFormValues"
      is-update-form
      @change="$emit('change');"
    />
  </div>
</template>

<script>
import dayjs from '@/plugins/dayjs';
import TimeRegistrationUpdate from '@/application/models/TimeRegistrationUpdate.js';
import TimeRegistrationUpdateForm from '@/modules/timeRegistration/components/TimeRegistrationUpdateForm.vue';
import { show, update } from '@/modules/timeRegistration/api';
import { mapGetters } from 'vuex';

export default {
  name: 'InvoiceTimeRegistrationDialog',
  components: {
    TimeRegistrationUpdateForm,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
    },
  },
  data: () => ({
    generalInfo: ['employer', 'department', 'position'],
    updateFormValues: new TimeRegistrationUpdate(),
    updateDialog: false,
    headers: [],
  }),
  computed: {
    ...mapGetters({ can: 'authorisation/can' }),
    showFooter() {
      return this.values.timeRegistrations.length <= 10;
    },
  },
  created() {
    this.headers = [
      {
        text: this.$t('invoice.preview.timeRegistrationDialog.headers.candidate'),
        value: 'candidate',
        align: 'start',
      },
      {
        text: this.$t('invoice.preview.timeRegistrationDialog.headers.date'),
        value: 'date',
        align: 'end',
      },
      {
        text: this.$t('invoice.preview.timeRegistrationDialog.headers.from'),
        value: 'from',
        align: 'end',
        width: 120,
      },
      {
        text: this.$t('invoice.preview.timeRegistrationDialog.headers.to'),
        value: 'to',
        align: 'end',
        width: 120,
      },
      {
        text: this.$t('invoice.preview.timeRegistrationDialog.headers.hoursTotalTime'),
        value: 'hoursTotalTime',
        align: 'end',
        width: 120,
      },
      {
        text: this.$t('invoice.preview.timeRegistrationDialog.headers.hoursTotalBreak'),
        value: 'hoursTotalBreak',
        align: 'end',
        width: 120,
      },
      {
        text: this.$t('invoice.preview.timeRegistrationDialog.headers.hoursTotalWorked'),
        value: 'hoursTotalWorked',
        align: 'end',
        width: 140,
      },
    ];

    if (this.can('timeRegistration.update')) {
      this.headers.push( {
        value: 'actions',
        align: 'end',
        cellClass: 'no-table-separator',
        sortable: false,
        text: '',
      });
    }
  },
  methods: {
    dayjs,
    closeDialog() {
      this.$emit('cancel');
    },
    async openUpdate(item) {
      this.updateFormValues = new TimeRegistrationUpdate();
      const response = await show(item.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateFormValues.isBilled = item.isBilled;
      this.updateDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
  },
};
</script>

<style lang="scss" scoped>
table.general-info {
  line-height: 2;
  tr td:first-child {
    width: 0;
    white-space: nowrap;
    padding-right: 12px;
  }
}
</style>
